<template>
	<div>
		<common-table
				:itemScope="['lsell', 'storePrice', 'lreal']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				pageTitle="预约场所"
				:listDeploy="listDeploy"
				@beforeOpenAddDialog="beforeOpenAddDialog"
				@otherRightOperate="otherRightOperate"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:storePrice="{ item }">
				<v-icon class="iconfont ml-2" color="primary" size="24" @click="storePrice(item.code)">iconfont icon-chakan-fill
				</v-icon>
			</template>
		</common-table>
		<template v-if="storePriceDialog">
			<store-price
					:code="appointmentCode"
					:storePriceDialog.sync="storePriceDialog"
					@closeStorePriceDialog="storePriceDialog=false"/>
		</template>
		<template v-if="timeDialog">
			<TimeRangeManage :timeDialog.sync="timeDialog" @closeTimeDialog="timeDialog = false"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/appointmentPlace.js';
import TimeRangeManage from "./timeRange-manage";
import storePrice from "./appointmentStorePrice";

export default {
	mixins: [commonCurd],
	components: {TimeRangeManage, storePrice},
	data() {
		return {
			api,
			timeDialog: false,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			appointmentCode: '',
			storePriceDialog: false,
			rightConfig: [
				{label: '时段管理', icon: 'shijian', event: 'timeRangeManage'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			leftConfig: [{type: 'input', label: '输入预约场所代码/名称', property: 'searchkey', width: '200px'}],
			headers: [
				{text: '预约场所代码', value: 'code'},
				{text: '预约场所名称', value: 'description'},
				{text: '关联时段', value: 'periods'},
				{text: '排序号', value: 'seq'},
				{text: '库存&价格', value: 'storePrice'},
				{text: '状态', value: 'lsell'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "预约场所代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "预约场所名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'periods',
						connector: {
							props: {
								required: true,
								label: "关联时段",
								items: [],
								multiple: true,
								chips: true,
								arrayType: false
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'outcode',
						connector: {
							props: {
								required: false,
								label: "闸机票型编码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'dfallownum',
						connector: {
							props: {
								required: true,
								label: "默认库存"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'dfprice',
						connector: {
							props: {
								required: false,
								label: "默认价格"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'usetips',
						connector: {
							props: {
								required: false,
								label: "核销提示",
								items: [],
								chips: true
							}
						}
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					}
				]
			}
		}
	},
	methods: {
		// 库存&价格
		storePrice(item) {
			this.storePriceDialog = true;
			this.appointmentCode = item;
		},
		createdExtend() {
			this.fixedParams.group = this.$route.query.value;
		},
		beforeSave(form) {
			form.groupid = this.$route.query.value;
		},
		beforeOpenAddDialog() {
			this.axios.post(this.select_data, {keyname: 'actperiod,usetips'}).then((res) => {
						if (res?.data) {
							this.listDeploy.row[2].connector.props.items = res.data[0].values;
							this.listDeploy.row[7].connector.props.items = res.data[1].values;
						}
					}
			);
		},
		otherRightOperate(event) {
			if (event === 'timeRangeManage')
				this.timeDialog = true
		}
	}
}
</script>

<style scoped lang="scss">

</style>
