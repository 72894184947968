<template>
	<div>
		<exhibition-data-box
				:dialog.sync="dialog"
				title="库存&价格"
				:code="code"
				:contentHeight="fixedHeight + 50"
		>
			<common-table
					:key="new Date().getTime()"
					:headers="headers"
					:listData="listData"
					:loading="loading"
					:showPagination=false
					:dialogTable=true
					ref="ct"
					@search="search"
					:fixedHeight="fixedHeight"
					:rightConfig="rightConfig"
					:leftConfig="leftConfig"
					:normalPage="false"
					:showLeftSearchBtn="false"
					@otherRightOperate="otherRightOperate"
					@saveTableFiled="saveTableFiled"
			/>
		</exhibition-data-box>
		<template v-if="batchDialog">
			<form-menu
					:center="true"
					:center_btn="true"
					:listDeploy="listDeploy"
					height="448px"
					width="400px"
					:ridDivision="true"
					:dialog.sync="batchDialog"
					rightbtn_text="确定"
					leftbtn_text="取消"
					@resetForm="batchDialog = false"
					@validateSave="validateSave"
					ref="formRef"
					:title="batchTitle"/>
		</template>
	</div>
</template>

<script>
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/appointmentStorePrice.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import storePrice from '../storePrice';

export default {
	components: {exhibitionDataBox, formMenu},
	mixins: [commonCurd, storePrice],
	props: {
		code: {
			type: String,
			default: () => ''
		},
		storePriceDialog: {
			type: Boolean,
			default: () => false
		}
	},
	data() {
		return {
			api,
			fixedHeight: 0,
			headers: [
				{text: '日期', value: 'date'},
				{text: '售卖价格', value: 'rate', editable: true},
				{text: '剩余库存', value: 'avl', editable: true},
				{text: '已售', value: 'pickup'}
			],
			dialog: false,
			rightConfig: [
				{label: '批量价格', icon: '', event: 'batchPrice'},
				{label: '批量库存', icon: '', event: 'batchStore'}
			],
			leftConfig: [
				{type: 'select', label: '', property: 'period', items: []}
			],
			batchDialog: false,
			batchTitle: '',
			listDeploy: {},
			otherDeploy: {
				cols: 12,
				subassembly: "WAutocomplete",
				property: 'period',
				connector: {
					props: {
						required: true,
						label: "关联时段",
						items: [],
						multiple: true,
						chips: true,
						arrayType: false
					}
				}
			}
		}
	},
	methods: {
		async createdExtend() {
			this.fixedParams.searchkey = this.code;
			this.form.pages.pagesize = 999
			const res = await this.axios.post(this.select_data, {
				keyname: 'actperiod', "params": [
					{
						"key": "actperiod",
						"value": this.code
					}
				]
			})
			this.leftConfig[0].items = res.data[0].values
			const code = res.data[0].values[0].code
			this.form['period'] = code
			this.setLabel(code)
		},
		search(form) {
			this.form = {pages: this.form.pages};
			this.form = Object.assign(this.form, form);
			this.setLabel(this.form.period)
			this.list();
		},
		setLabel(code) {
			this.leftConfig[0].items.forEach(item => {
				if (code === item.code) {
					this.leftConfig[0].label = item.desc
				}
			})
		},
		otherRightOperate(event) {
			this.axios.post(this.select_data, {keyname: 'actperiod'}).then((res) => {
						if (res?.data) {
							this.otherDeploy.connector.props.items = res.data[0].values
							this.batchDialog = true;
							let listDeploy = {}
							if (event === this.rightConfig[0].event) {
								this.batchTitle = this.rightConfig[0].label;
								listDeploy = this.getDeploy('price', '售卖价格');
							} else if (event === this.rightConfig[1].event) {
								this.batchTitle = this.rightConfig[1].label;
								listDeploy = this.getDeploy('avl', '剩余库存');
							}
							listDeploy.row.unshift(this.otherDeploy)
							listDeploy.row.push({
								cols: 6,
								subassembly: "VCheckbox",
								property: 'regenerate',
								connector: {
									props: {
										label: '生成日期'
									}
								}
							})
							this.listDeploy = listDeploy
						}
					}
			);
		},
		validateSave(item, callback) {
			if (item.done) {
				item.form.sitecode = this.code;
				this.axios.post(this.api.save, item.form).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("保存成功");
						callback();
						this.list();
					} else {
						this.$refs.formRef.load = false;
					}
				});
			}
		}
	},
	watch: {
		storePriceDialog: {
			handler(v) {
				if (v) {
					this.dialog = true;
				}
			},
			immediate: true
		},
		dialog: {
			handler(v) {
				if (!v) {
					this.$emit("closeStorePriceDialog");
				}
			}
		}
	},
	mounted() {
		this.fixedHeight = document.documentElement.clientHeight * 0.6
	}
}
</script>

<style scoped>

</style>
