<template>
	<div>
		<exhibition-data-box :dialog.sync="dialog" title="预约时段管理" :contentHeight="fixedHeight + 90">
			<common-table
					:key="new Date().getTime()"
					:headers="headers"
					:listData="listData"
					:loading="loading"
					:dialogTable=true
					ref="ct"
					pageTitle="时段管理"
					@search="search"
					:fixedHeight="fixedHeight"
					:rightConfig="rightConfig"
					:normalPage="false"
					@remove="remove"
					:leftConfig="leftConfig"
					:btnAction="true"
					:btnActionEvent="btnActionEvent"
					@otherRightOperate="otherRightOperate"
					@otherTableOperate="otherTableOperate"
			/>
		</exhibition-data-box>
		<template v-if="addDialog">
			<form-menu
					:center="true"
					:center_btn="true"
					:listDeploy="listDeploy"
					height="320px"
					width="520px"
					:ridDivision="true"
					:dialog.sync="addDialog"
					rightbtn_text="确定"
					leftbtn_text="取消"
					@resetForm="addDialog = false"
					@validateSave="validateSave"
					ref="formRef"
					title="新建时段"/>
		</template>
	</div>
</template>

<script>
import ExhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box";
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/timeRanage-manage.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import rangeTimePicker from "@/components/timePicker/rangeTimePicker.vue";

export default {
	name: "timeRange-manage",
	components: {
		ExhibitionDataBox,
		formMenu
	},
	mixins: [commonCurd],
	props: {
		timeDialog: Boolean
	},
	data() {
		return {
			api,
			dialog: false,
			btnActionEvent: [
				{
					event: 'editItem1',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				},
				{
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				}
			],
			fixedHeight: 0,
			headers: [
				{text: '时段代码', value: 'code'},
				{text: '时段范围', value: 'time'},
				{text: '时段描述', value: 'description'},
				{text: '操作', value: 'actions'}
			],
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add1'}
			],
			leftConfig: [
				{type: 'input', label: '输入时段代码/名称', property: 'searchkey', width: '200px'}
			],
			addDialog: false,
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "时段代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: false,
								label: "时段描述"
							}
						}
					},
					{
						cols: 6,
						subassembly: rangeTimePicker,
						property: 'time',
						connector: {
							props: {
								required: true,
								startText: '时段范围',
								endText: ''
							}
						}
					},
				]
			}
		}
	},
	watch: {
		timeDialog: {
			handler(v) {
				if (v) {
					this.dialog = true;
				}
			},
			immediate: true
		},
		dialog: {
			handler(v) {
				if (!v) {
					this.$emit("closeTimeDialog");
				}
			}
		}
	},
	mounted() {
		this.fixedHeight = document.documentElement.clientHeight * 0.6;
	},
	methods: {
		otherRightOperate(event) {
			if (event === 'add1') {
				this.listDeploy.sqlid = 0
				for (const obj of this.listDeploy.row) {
					delete obj.default
					if (obj.connector?.props) {
						obj.connector.props.disabled = false
						delete obj.connector.props.value
					}
				}
				this.addDialog = true
			}
		},
		validateSave(item, callback) {
			if (item.done) {
				item.form.sqlid = this.listDeploy.sqlid;
				this.axios.post(this.api.save, item.form).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("保存成功");
						callback();
						this.list();
					} else {
						this.$refs.formRef.load = false;
					}
				});
			}
		},
		async otherTableOperate(event, object) {
			const sqlid = object.sqlid
			if (event === this.btnActionEvent[0].event) {
				const item = await this.getObject(sqlid)
				this.listDeploy.sqlid = sqlid;
				for (const obj of this.listDeploy.row) {
					obj.default = item[obj.property]
					if (obj.hasEditDisabledProperty) {
						obj.connector.props.disabled = true;
					}
				}
				this.addDialog = true
			}
		}
	}
}
</script>

<style scoped>

</style>
