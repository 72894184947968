import { render, staticRenderFns } from "./appointmentStorePrice.vue?vue&type=template&id=4d8e5c72&scoped=true&"
import script from "./appointmentStorePrice.vue?vue&type=script&lang=js&"
export * from "./appointmentStorePrice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8e5c72",
  null
  
)

export default component.exports